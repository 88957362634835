<template>
  <div>
    <CModal style="color: #4f5d73" :show.sync="callStaffModal">
      <div @click="updateStatus" class="mt-3 ml-3 d-flex justify-content-end">
        <small class="" aria-label="Close">
          <strong class="pr-4">
            <img src="/img/close.png" width="40px" style="opacity: 0.8;">
          </strong>
        </small>
      </div>
      <div class="text-center">
        <div class="text-center mb-3">
          <h4>{{ $t('callStaff') }}</h4>

        </div>
        <CAlert color="success" v-if="alertMessage">
          <h4 class="pt-2">{{ messageStatus }}
            <CSpinner v-if="!messageStatus" class="mb-1" component="span" size="sm" aria-hidden="true" />
          </h4>
        </CAlert>

        <CRow class="row row-cols-2  p-4 text-center" style="display: contents;">
          <CRow class="row row-cols-2  p-4 text-center" style="display: contents;">
            <CButton class="mt-2 btn-square-callstaff col m-2 p-4 text-center" data-toggle="collapse"
              data-target="#collapseExample" :disabled="alertMessage">
              <i class="fi fi-rr-restaurant" style="font-size: 40px;"></i>
              <br>
              {{ $t('cutlery') }}
            </CButton>
            <CButton class="mt-2 btn-square-callstaff col p-4 m-2  text-center" @click="confirmRequest('ขอเครื่องปรุง')"
              :disabled="alertMessage">
              <i class="fi fi-rr-salt-pepper" style="font-size: 40px;"></i>
              <br>
              {{ $t('seasoning') }}
            </CButton>
          </CRow>

          <div class="collapse col-12" id="collapseExample">
            <div class="row justify-content-center" style="aspect-ratio: 1/1;">
              <div class="text-center m-2" v-for="(requestStaff, index) in requestStaff" :key="'callStaf' + index">
                <CButton class="mt-2 btn-square-callstaff text-center text-dark" style="background-color:#f6f6f6;"
                  @click="confirmRequest($i18n.t(requestStaff.name, 'th'))" :disabled="alertMessage">
                  <i :class="requestStaff.icon" style="font-size: 40px;"></i>
                  <br>
                  {{ $t(requestStaff.name) }}
                </CButton>
              </div>
            </div>

          </div>

          <CButton class="mt-3 btn-square-callstaff col p-4 m-2 text-center text-dark"
            @click="confirmRequest('เติมเครื่องดื่ม')" :disabled="alertMessage">
            <i class="fi fi-rr-drink-alt" style="font-size: 40px;"></i>
            <br>
            {{ $t('refillDrink') }}
          </CButton>
          <CButton class="mt-3 btn-square-callstaff text-dark col p-4 m-2 text-center"
            @click="confirmRequest('ชำระเงิน')" :disabled="alertMessage">
            <i class="fi fi-rr-usd-circle" style="font-size: 40px;"></i>
            <br>
            {{ $t('payBill') }}
          </CButton>
          <CButton class="mt-3 btn-square-callstaff text-dark col p-4  m-2 text-center" @click="messagebox = true"
            :disabled="alertMessage">
            <i class="fi fi-rr-pen-field" style="font-size: 40px;"></i>
            <br>
            {{ $t('other') }}
          </CButton>
          <CButton class="mt-3 btn-square-callstaff text-dark col p-4  m-2 text-center" style="visibility: hidden;">
          </CButton>
        </CRow>

      </div>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>

    <!-- Modal For Input Message to Stuff  -->

    <CModal :show.sync="messagebox" size="sm">
      <div class="text-center mt-5">
        <i class="fi fi-rr-pen-field mt-4" style="font-size: 40px;color: #3c4b64;"></i>

        <div class="text-center">
          <h4 class="mt-1">{{ $t('callStaff') }}</h4>
          <h6 class="mb-3">{{ $t('callStaffDialog') }}</h6>
        </div>

        <input class="inputbox mt-4 mb-4" type="text" id="textInput" style="height: 100px;" v-model="textInput" />
        <div style="text-align: center;">
          <CButton class="mt-2 m-1" style="width: 70px;" color="secondary" @click="messagebox = false">{{ $t('cancel')
          }}
          </CButton>
          <CButton class="mt-2 m-1" style="width: 70px;" color="success" @click="confirmRequest(textInput)">{{
            $t('confirm') }}</CButton>
        </div>


      </div>

      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>


  </div>
</template>

<script>
import '@/util/menu.css'
import { CButton, CModal } from '@coreui/vue'
import { mapGetters } from 'vuex'
import poscrm from '@/services/poscrm'
import moment, { ISO_8601 } from 'moment'

export default {
  props: ['callStaffModal'],
  components: {
    CModal,
    CButton
  },

  data() {
    return {
      receiptObjectId: '',
      confirmModal: false,
      messagebox: false,
      localCallStaffModal: this.callStaffModal,
      table: {},
      textInput: '',
      alertMessage: false,
      requestStaff: [
        { name: 'spoon', icon: 'fi fi-rr-restaurant' },
        { name: 'knife', icon: 'fi fi-rr-scalpel' },
        { name: 'chopstick', icon: 'fi fi-rr-noodles' },
        { name: 'cup', icon: 'fi fi-rr-bowl-chopsticks' },
        { name: 'plate', icon: 'fi fi-rr-plate' },
        { name: 'glass', icon: 'fi fi-rr-glass' }
      ],
      messageStatus: '',
    }
  },
  computed: {
    ...mapGetters(['shopLineOA']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId() {
      return `${localStorage.getItem('shopObjectId')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopObjectId
      )
    },
    tableObjectId() {
      return `${localStorage.getItem('tableObjectId')}`
    },

  },
  created() {
    this.table = JSON.parse(localStorage.getItem('table')) || []
    this.receiptObjectId = localStorage.getItem('receiptObjectId') || ''
  },
  methods: {
    getRequestStaffMessage(index) {
      return this.requestStaff[index].name
    },
    trackButtonClick(buttonLabel) {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'call_staff', {
          event_label: buttonLabel,
        });
      } else {
        console.error('gtag is not defined.');
      }
    },
    confirmRequest(message) {
      this.createRequest(message);
      this.alertMessage = true;
      this.messagebox = false;
      this.trackButtonClick(message)


    },
    updateStatus() {
      this.localCallStaffModal != this.localCallStaffModal
      this.$emit('update-status', this.localCallStaffModal)
    },
    async createRequest(message) {
      this.table = JSON.parse(localStorage.getItem('table')) || []
      this.receiptObjectId = localStorage.getItem('receiptObjectId') || ''

      let data = {
        "id": "",
        "uid": this.uid,
        "receiptObjectId": this.receiptObjectId,
        "shop": {
          "id": this.shop.id,
          "objectId": this.shopObjectId,
          "shopName": this.shop.shopName,
          "branchName": this.shop.branchName
        },
        "scope": "table",
        "scopeData": {
          "id": this.table.id || this.table.objectId,
          "objectId": this.table.objectId
        },
        "title": "New Message",
        "message": message,
        "created_at": moment().toISOString(),
        "status": "new",
        "action": "alert",
        "priority": "low",
        "read_at": null,
        "expired": null,
        "group": "none",
        "ref": "none",
        "tableLayout": localStorage.getItem('tableLayout') || false,
      }
      try {
        await poscrm({
          url: process.env.VUE_APP_MESSAGE_CALLSTAFF,
          data: data,
          method: 'post',
        }).then((res) => {
          switch (res.data?.error?.code) {
            case 9000:
              this.messageStatus = this.$i18n.t('startOrderBeforeCallStaff');
              break;
            case 0:
              this.messageStatus = this.$i18n.t('messageSent');
              break;
            case 4001:
              this.$router.push('/dine-in/selecteditem');
              break;
            default:
              break;
          }
          setTimeout(() => {
            this.alertMessage = false;
          }, 3000);
        })
      } catch (error) {
        throw new Error(error)
      }


    },

  },
  watch: {


  },
}
</script>

<style scoped>
.modal {
  padding: 1 rem;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 80%;
  border-radius: 3%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.inputbox {

  width: 100%;
  color: rgb(36, 35, 42);
  font-size: 16px;
  line-height: 20px;
  min-height: 28px;
  border-radius: 14px;
  padding: 8px 16px;
  border: 2px solid transparent;
  box-shadow: rgb(0 0 0 / 6%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
  background: rgb(255, 255, 255);
  transition: all 0.1s ease 0s;


}

.btn-square-callstaff:hover {
  color: #4f5d73;
}
</style>